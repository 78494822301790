import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import { BLOK_ARRAY } from "../../../prop_types";

const HEADING_BOTTOM_MARGIN = { marginBottom: "md" };

function SectionAboutCareerCta({
  ctaResponsive: ctaResponsiveArray,
  heading,
  subheading,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [ctaResponsive] = ctaResponsiveArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        {heading && (
          <Heading
            {...rest}
            balanceText
            isCentered
            margin={HEADING_BOTTOM_MARGIN}
            seoLevel={3}
            visualLevel={1}
          >
            {heading}
          </Heading>
        )}
        {subheading && (
          <Heading
            {...rest}
            balanceText
            color="gray"
            isCentered
            seoLevel={4}
            visualLevel={5}
          >
            {subheading}
          </Heading>
        )}
        {ctaResponsive && <CtaResponsive {...ctaResponsive} />}
      </Contain>
    </Section>
  );
}

SectionAboutCareerCta.propTypes = {
  ctaResponsive: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subheading: PropTypes.string,
};

SectionAboutCareerCta.defaultProps = {
  ctaResponsive: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subheading: null,
};

export default SectionAboutCareerCta;
