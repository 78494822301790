import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY } from "../../../prop_types";
import {
  hasMarginStyle,
  hasPaddingStyle,
  wrapperStyle,
} from "./wrapper.module.scss";

export default function Wrapper({ settings, children, hasMargin, hasPadding }) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const sectionClassNames = classNames(wrapperStyle, ...settingsClassNames, {
    [hasMarginStyle]: hasMargin,
    [hasPaddingStyle]: hasPadding,
  });

  return <div className={sectionClassNames}>{children}</div>;
}

Wrapper.defaultProps = {
  children: null,
  settings: null,
  hasMargin: false,
  hasPadding: false,
};

Wrapper.propTypes = {
  children: PropTypes.node,
  hasMargin: PropTypes.bool,
  settings: BLOK_ARRAY,
  hasPadding: PropTypes.bool,
};
