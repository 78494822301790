import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import BackgroundImage from "~components/molecules/background_image/background_image";
import { BLOK_ARRAY } from "../../../prop_types";
import BackgroundColor from "../background_color/background_color";
import BackgroundImageResponsive from "../background_image_responsive/background_image_responsive";
import Gradient from "../gradient/gradient";
import Wave from "../wave/wave";
import Wrapper from "../wrapper/wrapper";
import {
  hasMarginStyle,
  hasPaddingStyle,
  sectionStyle,
} from "./section.module.scss";

export default function Section({ id, children, sectionSettings, ...rest }) {
  const {
    waveTop: waveTopArray,
    waveBottom: waveBottomArray,
    gradient: gradientArray,
    backgroundImage: backgroundImageArray,
    backgroundImageResponsive: backgroundImageResponsiveArray,
    backgroundColor: backgroundColorArray,
    hasOuterMargin,
    hasOuterPadding,
    hasInnerMargin,
    hasInnerPadding,
  } = sectionSettings || {};

  const [waveTop] = waveTopArray || [];
  const [waveBottom] = waveBottomArray || [];
  const [gradient] = gradientArray || [];

  const [backgroundImage] = backgroundImageArray || [];
  const [backgroundImageResponsive] = backgroundImageResponsiveArray || [];
  const [backgroundColor] = backgroundColorArray || [];

  const sectionClassNames = classNames(sectionStyle, {
    [hasMarginStyle]: hasOuterMargin,
    [hasPaddingStyle]: hasOuterPadding,
  });

  return (
    <section id={id} className={sectionClassNames} data-testid="section">
      {waveTop && <Wave {...waveTop} position="top" />}

      {backgroundColor && <BackgroundColor {...backgroundColor} />}

      {gradient && <Gradient {...gradient} />}
      {backgroundImageResponsive && (
        <BackgroundImageResponsive {...backgroundImageResponsive} />
      )}
      {backgroundImage && (
        <BackgroundImage {...backgroundImage} objectFit="cover" />
      )}
      <Wrapper hasMargin={hasInnerMargin} hasPadding={hasInnerPadding}>
        <SbEditable content={rest}>{children}</SbEditable>
      </Wrapper>
      {waveBottom && <Wave {...waveBottom} position="bottom" />}
    </section>
  );
}

Section.defaultProps = {
  id: null,
  children: null,
  sectionSettings: {
    waveTop: null,
    waveBottom: null,
    gradient: null,
    backgroundGradient: null,
    backgroundImage: null,
    backgroundImageResponsive: null,
    hasOuterMargin: false,
    hasOuterPadding: false,
    hasInnerMargin: false,
    hasInnerPadding: false,
  },
};

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  sectionSettings: PropTypes.shape({
    waveTop: BLOK_ARRAY,
    waveBottom: BLOK_ARRAY,
    gradient: BLOK_ARRAY,
    backgroundGradient: BLOK_ARRAY,
    backgroundImage: BLOK_ARRAY,
    backgroundImageResponsive: BLOK_ARRAY,
    hasOuterMargin: PropTypes.bool,
    hasOuterPadding: PropTypes.bool,
    hasInnerMargin: PropTypes.bool,
    hasInnerPadding: PropTypes.bool,
  }),
};
