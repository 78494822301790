import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import AwardItem from "~components/organisms/awards_list/award_item";
import AwardsList from "~components/organisms/awards_list/awards_list";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionAboutOurAwards({
  awardList,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [sectionSettings] = sectionSettingsArray || [];

  if (awardList?.length > 0) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <Contain>
          {heading && (
            <Heading seoLevel={3} visualLevel={2} {...rest}>
              {heading}
            </Heading>
          )}
          {/* ————— AWARD LIST ————— */}
          <AwardsList>
            {awardList.map((awardItem) => {
              return <AwardItem {...awardItem} key={awardItem.title} />;
            })}
          </AwardsList>
        </Contain>
      </Section>
    );
  }

  return null;
}

SectionAboutOurAwards.propTypes = {
  awardList: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
};

SectionAboutOurAwards.defaultProps = {
  awardList: null,
  heading: null,
  id: null,
  sectionSettings: null,
};

export default SectionAboutOurAwards;
