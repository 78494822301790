import React from "react";
import { BLOK_ARRAY } from "../../../prop_types";
import { awardListStyle } from "./awards_list.module.scss";

export default function AwardsList({ children }) {
  return <div className={awardListStyle}>{children}</div>;
}

AwardsList.defaultProps = {
  children: null,
};

AwardsList.propTypes = {
  children: BLOK_ARRAY,
};
