import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Button from "~components/molecules/button/button/button";
import { LINK } from "../../../prop_types";
import * as classes from "./cta_responsive.module.scss";

export default function CtaResponsive({
  primaryText,
  primaryLink,
  primaryColor,
  secondaryText,
  secondaryLink,
  secondaryColor,
  isCentered,
  ...rest
}) {
  const classNames = [
    classes.ctaResponsive,
    isCentered ? classes.isCentered : "",
  ];

  return (
    <SbEditable content={rest}>
      <div className={classNames.join(" ")}>
        <div>
          <Button
            link={primaryLink}
            color={primaryColor}
            text={primaryText}
            hasChevron
            chevronType="chevronRight"
            isFullWidth
            testId="cta-responsive-primary-button"
          />
        </div>
        {secondaryText && secondaryLink && secondaryColor && (
          <div className={classes.ctaWrapper}>
            <Button
              link={secondaryLink}
              color={`${secondaryColor}Transparent`}
              text={secondaryText}
              isFullWidth
              testId="cta-responsive-secondary-button"
            />
          </div>
        )}
      </div>
    </SbEditable>
  );
}

CtaResponsive.defaultProps = {
  secondaryText: null,
  secondaryLink: null,
  secondaryColor: null,
  isCentered: false,
};

CtaResponsive.propTypes = {
  primaryText: PropTypes.string.isRequired,
  primaryLink: LINK.isRequired,
  primaryColor: PropTypes.oneOf(["yellow", "white"]).isRequired,
  secondaryText: PropTypes.string,
  secondaryLink: LINK,
  secondaryColor: PropTypes.oneOf(["", "yellow", "white"]),
  isCentered: PropTypes.bool,
};
