import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "~components/molecules/background_image/background_image";
import DesktopOnly from "~components/utils/responsive/desktop_only";
import MobileOnly from "~components/utils/responsive/mobile_only";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY } from "../../../prop_types";
import * as classes from "./background_image_responsive.module.scss";

export default function BackgroundImageResponsive({
  className,
  settings,
  desktopBackgroundImage: desktopBackgroundImageArray,
  mobileBackgroundImage: mobileBackgroundImageArray,
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const [desktopBackgroundImage] = desktopBackgroundImageArray || [];
  const [mobileBackgroundImage] = mobileBackgroundImageArray || [];
  const classNames = [classes.backgroundImageResponsive, ...settingsClassNames];

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(" ")}>
      {desktopBackgroundImage && (
        <DesktopOnly>
          <BackgroundImage {...desktopBackgroundImage} />
        </DesktopOnly>
      )}
      {mobileBackgroundImage && (
        <MobileOnly>
          <BackgroundImage {...mobileBackgroundImage} />
        </MobileOnly>
      )}
    </div>
  );
}

BackgroundImageResponsive.defaultProps = {
  className: null,
  desktopBackgroundImage: null,
  mobileBackgroundImage: null,
  settings: null,
};

BackgroundImageResponsive.propTypes = {
  className: PropTypes.string,
  desktopBackgroundImage: BLOK_ARRAY,
  mobileBackgroundImage: BLOK_ARRAY,
  settings: BLOK_ARRAY,
};
