import PropTypes from "prop-types";
import React from "react";

import * as classes from "./mobile_only.module.scss";

export default function MobileOnly({ children }) {
  return <div className={classes.mobileOnly}>{children}</div>;
}

MobileOnly.propTypes = {
  children: PropTypes.node.isRequired,
};

MobileOnly.defaultProps = {};
