import React from "react";
import PropTypes from "prop-types";
import Image from "~components/atoms/image/image";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY } from "../../../../../prop_types";
import { contentListStyle } from "./content_list.module.scss";

function ContentList({ list }) {
  if (list?.length > 0) {
    return (
      <ul className={contentListStyle} data-testid="content-list">
        {list.map(
          ({
            image: imageArray,
            contentRichText: contentRichTextArray,
            _uid,
          }) => {
            const [contentRichText] = contentRichTextArray || [];
            const [image] = imageArray || [];

            return (
              <li key={_uid}>
                <Image {...image} />
                <RichText {...contentRichText} />
              </li>
            );
          }
        )}
      </ul>
    );
  }

  return null;
}

ContentList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: BLOK_ARRAY,
      contentRichText: BLOK_ARRAY,
    })
  ),
};

ContentList.defaultProps = {
  list: null,
};

export default ContentList;
