import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import RichText from "~components/molecules/rich_text/rich_text";
import ValuesItem from "~components/molecules/values_item/values_item";
import { BLOK_ARRAY } from "../../../prop_types";
import { gridWrapperStyle } from "./section_our_values.module.scss";

const GRID_SPLIT = { XXL: 2, S: 1 };

function SectionOurValues({
  bodyRichText: bodyRichTextArray,
  cta: ctaArray,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  valuesItems,
  ...rest
}) {
  const [bodyRichText] = bodyRichTextArray || [];
  const [cta] = ctaArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color="gray"
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading {...rest} balanceText seoLevel={4} visualLevel={2}>
            {heading}
          </Heading>
        )}
        {bodyRichText && <RichText {...bodyRichText} />}
        {valuesItems?.length > 0 && (
          <div className={gridWrapperStyle}>
            <GridNew split={GRID_SPLIT}>
              {valuesItems.map((value) => {
                return <ValuesItem {...value} key={value.heading} />;
              })}
            </GridNew>
          </div>
        )}
        {cta && <CtaResponsive {...cta} />}
      </Contain>
    </Section>
  );
}

SectionOurValues.propTypes = {
  bodyRichText: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
  valuesItems: BLOK_ARRAY,
};

SectionOurValues.defaultProps = {
  bodyRichText: null,
  cta: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
  valuesItems: null,
};

export default SectionOurValues;
