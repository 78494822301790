import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Card from "~components/atoms/card/card";
import TextLink from "~components/molecules/text_link/text_link";
import { LINK } from "../../../prop_types";
import {
  awardIconStyle,
  awardItemWrapperStyle,
  awardTitleStyle,
  awardsSectionItemStyle,
} from "./award_item.module.scss";

export default function AwardItem({ title, cta, ctaLink, ...rest }) {
  return (
    <SbEditable content={rest}>
      <div className={awardItemWrapperStyle}>
        <Card isFullWidth>
          <div className={awardsSectionItemStyle} data-testid="award-item">
            <svg className={awardIconStyle} role="img">
              <use xlinkHref="/images/icon_award.svg#icon" />
            </svg>
            <div>
              {title && title.length && (
                <div className={awardTitleStyle} data-testid="award-title">
                  {title}
                </div>
              )}
              {ctaLink && cta && (
                <div>
                  <TextLink title={cta} link={ctaLink} />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </SbEditable>
  );
}

AwardItem.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.string,
  ctaLink: LINK,
};

AwardItem.defaultProps = {
  title: null,
  cta: null,
  ctaLink: null,
};
