import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import { heroTopPaddingStyle } from "./section_centered_hero_wrapper.module.scss";

function SectionCenteredHeroWrapper({
  heading,
  headingColor,
  heroTopPadding,
  id,
  sectionSettings: sectionSettingsArray,
  subheading: subheadingArray,
  ...rest
}) {
  const [subheading] = subheadingArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (heroTopPadding) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <div className={heroTopPaddingStyle}>
          <Contain isContentCentered>
            {heading && (
              <Heading
                {...rest}
                balanceText
                color={headingColor}
                seoLevel={1}
                visualLevel={1}
              >
                {heading}
              </Heading>
            )}
            {subheading && <RichText {...subheading} />}
          </Contain>
        </div>
      </Section>
    );
  }

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain isContentCentered>
        {heading && (
          <Heading
            {...rest}
            balanceText
            color={headingColor}
            seoLevel={1}
            visualLevel={1}
          >
            {heading}
          </Heading>
        )}
        {subheading && <RichText {...subheading} />}
      </Contain>
    </Section>
  );
}

SectionCenteredHeroWrapper.propTypes = {
  heading: PropTypes.string,
  headingColor: COLOR,
  heroTopPadding: PropTypes.bool,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subheading: BLOK_ARRAY,
};

SectionCenteredHeroWrapper.defaultProps = {
  heading: null,
  headingColor: null,
  heroTopPadding: false,
  id: null,
  sectionSettings: null,
  subheading: null,
};

export default SectionCenteredHeroWrapper;
