import React from "react";
import PropTypes from "prop-types";
import * as classes from "./about_page_people_grid.module.scss";

export default function AboutPagePeopleGrid({ children }) {
  return (
    <div className={classes.aboutPagePeopleGrid} data-testid="people-grid">
      {children}
    </div>
  );
}

AboutPagePeopleGrid.defaultProps = {
  children: null,
};

AboutPagePeopleGrid.propTypes = {
  children: PropTypes.node,
};
