import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SectionAboutCareerCta from "~components/sections/section_about_career_cta/section_about_career_cta";
import SectionAboutOurAwards from "~components/sections/section_about_our_awards/section_about_our_awards";
import SectionAboutOurTeam from "~components/sections/section_about_our_team/section_about_our_team";
import SectionAboutWhatWeDo from "~components/sections/section_about_what_we_do/section_about_what_we_do";
import SectionCenteredHeroWrapper from "~components/sections/section_centered_hero_wrapper/section_centered_hero_wrapper";
import SectionImageGrid from "~components/sections/section_image_grid/section_image_grid";
import SectionOurValues from "~components/sections/section_our_values/section_our_values";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const TEMPLATE_COMPONENTS = {
  sectionAboutCareerCta: SectionAboutCareerCta,
  sectionAboutOurAwards: SectionAboutOurAwards,
  sectionImageGrid: SectionImageGrid,
  sectionAboutOurTeam: SectionAboutOurTeam,
  sectionAboutWhatWeDo: SectionAboutWhatWeDo,
  sectionCenteredHeroWrapper: SectionCenteredHeroWrapper,
  sectionOurValues: SectionOurValues,
};

export default function TemplateAboutPage(props) {
  const { story, pageContext } = usePageData(props);

  const { children } = story;
  const { subNavData } = pageContext;

  return (
    <TemplateGlobalEntry {...props} headerColor="blue" subNavData={subNavData}>
      {children?.length > 0 &&
        children.map((childComponent) => {
          return renderDynamicComponent({
            parentComponentName: "TemplateAboutPage",
            componentName: childComponent.component,
            componentsMap: TEMPLATE_COMPONENTS,
            ...childComponent,
          });
        })}
    </TemplateGlobalEntry>
  );
}

TemplateAboutPage.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateAboutPage.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  query TemplateAboutPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
