import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Card from "~components/atoms/card/card";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import RichText from "../rich_text/rich_text";
import {
  copyWrapperStyle,
  imageWrapperStyle,
  isReversedStyle,
  valuesItemStyle,
} from "./values_item.module.scss";

export default function ValuesItem({
  heading,
  headingColor,
  contentRichText: contentRichTextArray,
  image: imageArray,
  isReversed,
  ...rest
}) {
  const [contentRichText] = contentRichTextArray;
  const [image] = imageArray;

  const valueItemClassNames = classNames(valuesItemStyle, {
    [isReversedStyle]: isReversed,
  });

  return (
    <SbEditable content={rest}>
      <Card stretch isFullWidth>
        <div className={valueItemClassNames} data-testid="values-item">
          <div className={copyWrapperStyle}>
            <Heading
              {...rest}
              visualLevel={3}
              seoLevel={5}
              color={headingColor}
            >
              {heading}
            </Heading>
            <RichText {...contentRichText} />
          </div>
          <div className={imageWrapperStyle}>
            <Image {...image} />
          </div>
        </div>
      </Card>
    </SbEditable>
  );
}

ValuesItem.defaultProps = {
  contentRichText: null,
  heading: null,
  headingColor: null,
  image: null,
  isReversed: false,
};

ValuesItem.propTypes = {
  contentRichText: BLOK_ARRAY,
  heading: PropTypes.string,
  headingColor: COLOR,
  image: BLOK_ARRAY,
  isReversed: PropTypes.bool,
};
