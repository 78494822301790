import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Section from "~components/atoms/section/section";
import BackgroundImage from "~components/molecules/background_image/background_image";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import RichText from "~components/molecules/rich_text/rich_text";
import AboutPagePeopleGrid from "~components/organisms/about_page_people_grid/about_page_people_grid";
import AboutPagePeopleGridItem from "~components/organisms/about_page_people_grid/about_page_people_grid_item";
import { BLOK_ARRAY } from "../../../prop_types";

const GRID_SPLIT = { XXL: 2, M: 1 };

function SectionAboutOurTeam({
  backgroundImage: backgroundImageArray,
  cta: ctaArray,
  gridBodyRichText: gridBodyRichTextArray,
  gridHeading,
  gridImages,
  heading,
  id,
  peopleGrid,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [backgroundImage] = backgroundImageArray || [];
  const [cta] = ctaArray || [];
  const [gridBodyRichText] = gridBodyRichTextArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      {backgroundImage && <BackgroundImage {...backgroundImage} />}
      <Contain>
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color="gray"
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading {...rest} balanceText seoLevel={4} visualLevel={2}>
            {heading}
          </Heading>
        )}
        {peopleGrid?.length > 0 && (
          <AboutPagePeopleGrid>
            {peopleGrid.map((person) => {
              return <AboutPagePeopleGridItem {...person} key={person._uid} />;
            })}
          </AboutPagePeopleGrid>
        )}
        <GridNew split={GRID_SPLIT}>
          <div>
            {gridHeading && (
              <Heading {...rest} balanceText seoLevel={4} visualLevel={2}>
                {gridHeading}
              </Heading>
            )}
            {gridBodyRichText && <RichText {...gridBodyRichText} />}
            {cta && <CtaResponsive {...cta} />}
          </div>
          {gridImages?.length > 0 &&
            gridImages.map((image) => {
              return <Image {...image} key={image._uid} testId="team-photo" />;
            })}
        </GridNew>
      </Contain>
    </Section>
  );
}

SectionAboutOurTeam.propTypes = {
  backgroundImage: BLOK_ARRAY,
  bodyRichText: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  gridBodyRichText: BLOK_ARRAY,
  gridHeading: PropTypes.string,
  gridImages: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  peopleGrid: BLOK_ARRAY,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionAboutOurTeam.defaultProps = {
  backgroundImage: null,
  bodyRichText: null,
  cta: null,
  gridBodyRichText: null,
  gridHeading: null,
  gridImages: null,
  heading: null,
  id: null,
  peopleGrid: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionAboutOurTeam;
