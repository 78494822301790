import React from "react";
import PropTypes from "prop-types";
import * as classes from "./background_color.module.scss";

export default function BackgroundColor({ color }) {
  const style = {
    background: color?.color || null,
  };

  return <div className={classes.backgroundColor} style={style} />;
}

BackgroundColor.defaultProps = {
  color: null,
};

BackgroundColor.propTypes = {
  color: PropTypes.shape({
    color: PropTypes.string,
  }),
};
