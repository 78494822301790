import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Image from "~components/atoms/image/image";
import AspectRatio from "~components/utils/aspect_ratio/aspect_ratio";
import { MEDIA } from "../../../prop_types";
import * as classes from "./about_page_people_grid_item.module.scss";

export default function AboutPagePeopleGridItem({
  image,
  quote,
  name,
  jobTitle,
  ...rest
}) {
  return (
    <SbEditable content={rest}>
      <div
        className={classes.aboutPagePeopleGridItemWrapper}
        data-testid="people-grid-item"
      >
        <AspectRatio ratio={1}>
          <div className={classes.aboutPagePeopleGridItem}>
            <Image image={image} />

            <div className={classes.textOverlay}>
              {quote && quote.length && <p>{quote}</p>}
              {name && name.length && <b>{name}</b>}
              {jobTitle && jobTitle.length && (
                <p className={classes.jobTitle}>{jobTitle}</p>
              )}
            </div>
          </div>
        </AspectRatio>
      </div>
    </SbEditable>
  );
}

AboutPagePeopleGridItem.defaultProps = {
  image: null,
  quote: null,
  name: null,
  jobTitle: null,
};

AboutPagePeopleGridItem.propTypes = {
  image: MEDIA,
  quote: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
};
