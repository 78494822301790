import { useMemo } from "react";

import { parseStoryblokEntry } from "~utils/storyblok/storyblok";

export default function usePageData(props) {
  const { data, pageContext } = props;
  const { nodes: entryAssets } = data?.assets || [];

  const story = useMemo(() => {
    return parseStoryblokEntry("story", props.data.story);
  }, [props.data.story]);

  return { story, pageContext, entryAssets, data };
}
