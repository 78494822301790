import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY } from "../../../prop_types";
import ContentList from "./components/content_list/content_list";
import { wrapperStyle } from "./section_about_what_we_do.module.scss";

const GRID_SPLIT = { XXL: 2, M: 1 };

function SectionAboutWhatWeDo({
  bodyRichText: bodyRichTextArray,
  contentList,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [bodyRichText] = bodyRichTextArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        <GridNew split={GRID_SPLIT}>
          <div className={wrapperStyle}>
            {subscriptHeading && (
              <Heading
                {...rest}
                balanceText
                color="gray"
                isUppercase
                seoLevel={4}
                visualLevel={7}
              >
                {subscriptHeading}
              </Heading>
            )}
            {heading && (
              <Heading {...rest} balanceText seoLevel={3} visualLevel={2}>
                {heading}
              </Heading>
            )}
            {bodyRichText && <RichText {...bodyRichText} />}
          </div>
          <ContentList list={contentList} />
        </GridNew>
      </Contain>
    </Section>
  );
}

SectionAboutWhatWeDo.propTypes = {
  bodyRichText: BLOK_ARRAY,
  contentList: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionAboutWhatWeDo.defaultProps = {
  bodyRichText: null,
  contentList: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionAboutWhatWeDo;
